// Modules
import React, { ReactChild } from "react";
import styled from "styled-components";

// Definitions
type Props = {
  children: ReactChild[] | ReactChild;
  isDisabled?: boolean;
  onClick?: () => void;
  className?: string;
};

type StyleProps = {
  theme: any;
  isDisabled?: boolean;
  onClick: () => void;
};

// Styles
const StyledButton = styled.span`
  min-width: 120px;
  height: 49px;
  padding: 0 20px;
  background: ${props => props.theme.light};
  color: ${props => props.theme.defaultTextColor};
  text-align: center;
  line-height: 49px;
  border-radius: 30px;
  display: inline-block;
  border: 1px solid ${(props: StyleProps) => props.theme.defaultTextColor};
  outline: none;
  transition: all 150ms ease-in;

  svg {
    fill: ${(props: StyleProps) => props.theme.defaultTextColor};
  }

  &:hover {
    cursor: pointer;
    background: ${(props: StyleProps) => props.theme.defaultTextColor};
    color: ${(props: StyleProps) => props.theme.light};

    svg {
      fill: ${(props: StyleProps) => props.theme.light};
    }
  }
`;

// Component / Hook
export const Button = (props: Props) => {
  const { onClick, isDisabled, children, className } = props;

  const onButtonClick = () => {
    if (!isDisabled && onClick) onClick();
  };

  return (
    <StyledButton
      onClick={onButtonClick}
      isDisabled={isDisabled}
      className={className}
    >
      {children}
    </StyledButton>
  );
};
