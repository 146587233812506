// Modules
import React, { useContext, useState } from "react";
import styled from "styled-components";

// Context
import { AppContext } from "../../ContextProvider";

// Components
import { Title } from "../../components/Title";
import { Intro } from "../../components/Intro";
import { TextField } from "../../components/TextField";
import { Button } from "../../components/Button";

// Styles
const Wrapper = styled.section``;

// Component / Hook
export const Login = () => {
  // Context
  const { dispatch } = useContext(AppContext);

  // States
  const [password, setPassword] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [validate, setValidate] = useState(false);

  // Functions
  const onChangeText = (value: string) => {
    setPassword(value);
  };

  const sendForm = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BACKEND}/admin/login`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password }),
      }
    );

    // API will return the created user id
    const { isValid, hash } = await response.json();

    setIsValid(isValid);

    if (isValid) {
      dispatch({ authkey: hash });
    } else {
      setValidate(true);
    }
  };

  return (
    <Wrapper>
      <Title label="Admin Login" />
      <Intro>Bitte melden Sie sich zuerst an.</Intro>
      <TextField
        value={password}
        onChange={onChangeText}
        isValid={isValid}
        validate={validate}
        type="password"
        errorMessage="Das Passwort ist nicht korrekt."
      />
      <Button onClick={sendForm}>Anmelden</Button>
    </Wrapper>
  );
};
