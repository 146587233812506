// Modules
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

// Context
import { AppContext } from "../../ContextProvider";

// Components
import { Title } from "../../components/Title";
import { Intro } from "../../components/Intro";
import { Loading } from "../../components/Loading";

// Definitions
interface IListItemProps {
  item: any;
}

// Component / Hook
const ListItem = (props: IListItemProps) => {
  // Context
  const {
    state: { authkey },
  } = useContext(AppContext);

  // Props
  const { item } = props;

  // States
  const [showAction, setShowAction] = useState(true);

  const complete = async () => {
    const accepted = confirm("Rechnung wirklich erstellen?");

    if (accepted) {
      const response = await fetch(
        `${process.env.REACT_APP_API_BACKEND}/admin/complete/${item.id}?authkey=${authkey}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status !== 200) {
        alert("An error occured!");
        return;
      }
      setShowAction(false);
    }
  };

  // Context
  const actionContent = showAction ? (
    <ConfirmButton onClick={complete}>Rechnung stellen</ConfirmButton>
  ) : (
    ""
  );
  return (
    <Listitem>
      <td>{item.id}</td>
      <td>{item.wm_id}</td>
      <td>{item.amount.toLocaleString()}</td>
      <td>{actionContent}</td>
    </Listitem>
  );
};

export const Start = () => {
  // Context
  const {
    state: { authkey },
  } = useContext(AppContext);

  // States
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  // Load data
  const loadData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BACKEND}/admin/list?authkey=${authkey}`
    );

    const { data, message } = await response.json();

    if (response.status !== 200) {
      alert(`An error occured (Error: ${message})!`);
      return;
    }

    setIsLoading(false);
    setData(data);
  };

  useEffect(() => {
    loadData();
  }, []);

  // Content
  const loadingContent = isLoading ? <Loading /> : "";
  const itemContent = !isLoading ? (
    <ItemWrapper>
      <Table>
        <tbody>
          <TableHeader>
            <TableHeaderItem>Order ID</TableHeaderItem>
            <TableHeaderItem>WM Id</TableHeaderItem>
            <TableHeaderItem>Anzahl</TableHeaderItem>
            <TableHeaderItem>Aktion</TableHeaderItem>
          </TableHeader>
          {data.map((item: any) => (
            <ListItem item={item} key={`listitem-${item.id}`} />
          ))}
        </tbody>
      </Table>
    </ItemWrapper>
  ) : (
    ""
  );

  return (
    <Wrapper>
      <Title label="Admin" />
      <Intro>Übersicht aller noch offenen Bestellungen für Pacvertise.</Intro>
      {loadingContent}
      {itemContent}
    </Wrapper>
  );
};

// Styles
const Wrapper = styled.section``;
const ItemWrapper = styled.div``;
const Listitem = styled.tr``;
const TableHeader = styled.tr`
  border-bottom: 1px solid black;
  font-weight: bold;
`;

const TableHeaderItem = styled.th`
  border-bottom: 1px solid black;
  padding: 5px 0px;
  margin-bottom: 20px;
`;

const Table = styled.table`
  width: 100%;
  text-align: left;
  line-height: 20px;
`;

const ConfirmButton = styled.span`
  &:hover {
    cursor: pointer;
    color: ${props => props.theme.primaryColor};
  }
`;
